<!-- <p>loader works!</p> -->
<!--
<div id='loaderStatusError' *ngIf="this.loaderStatus === LoaderStatus.Error">
    <div id='loaderText'><strong>{{environment.appFriendlyName}} is currently unavailable</strong><br />
        <br />This is likely to be a temporary problem, so please try again in a short while.
        <br />If the problem persists, please contact Customer Support.
    </div>
</div>
<div id='loaderStatusInfo' *ngIf="this.showStatusMessage"><div id='loaderText' [innerHTML]="this.statusMessageHTML" class="loaderTextClass"></div></div>
-->
<div id="loader">
<div id="loginChallenge" class="center login-div p-2 p-sm-4"  >
    <div class="w-100">
        <p style="font-size:large"><b>Just one more step....</b></p>
        <p style="font-size:medium">To get access to the Plans, we just need you to sign in.</p>
    </div>
    <div class="inner-container-div" >
        <div class="content-div1 " style="border-right:2px solid gray;padding:1.5rem;padding-left:0;padding-top:0;">
            
            <div><h3 style="font-weight:bold;margin-top:0;">Sign In</h3></div>
            <div class="form-group">
            <label for="username">Username</label>
            <input #loginEmail type="text" name="username" style="width:100%;line-height: 2;" 
            [(ngModel)]="this.email"  required=true/>
            </div>
            
            <div class="form-group">
            <label for="username">Password</label>
            <input  type="password" name="password" style="width:100%;line-height: 2;" #loginPassword id="login_password" 
             [(ngModel)]="this.password" required=true/>
            </div>
            <div class="form-group">
                <input type="checkbox" name="keepMeSignedIn" class="loader-checkbox"   [(ngModel)]="this.stayLoggedIn" style="margin-right: 5px;" /> 
                <label for="keepMeSignedIn" style="width:auto;display:inline-block">Keep me signed in</label>
            </div>
            <div class="form-group"> 
                <button #signInButton class="btn-action" (click)="signInClicked()"><span *ngIf="!this.signInClickedflag">Sign In</span><span *ngIf="this.signInClickedflag" class="spinner-border text-info slow btn-spinner" role="status"></span></button>
            </div>
            <div style="background-color: white;">
                <!-- <e-info-text *ngIf="this.loginStatus" [infoText]="getLoginInfoText()" [infoType]="getLoginInfoType()"></e-info-text> -->
            </div>
            <div class="form-group">
                <a href="javascript:void(0)" (click)="resetPassword()" class="reset-password-link">RESET PASSWORD</a>
            </div>  
        </div>
        <div class="content-div2">
            <div><h4 style="font-weight:bold;">Haven't got an account yet</h4></div>
            <div>
                <p>Register for a <strong>free</strong> emapsite account and join thousands of users who have used us to make ordering and managing maps easier.</p>
            </div>
            <div class="form-group" style="margin-top:1.5rem;">
                <div style="margin-bottom:1rem;">
                    <button class="btn-action-teal" #registerBtn  (click)="this.registerClicked()" >Register for free</button>
                </div>
                <!-- <div>
                    <button class="btn-action-teal" #guestLoginBtn  (click)="this.guestSignInClicked()" style="margin-top:0.5rem;" >Continue as Guest *</button>
                </div> -->
            </div>
        </div>

    </div>

</div>
<div  class="center w-60 d-none d-xl-inline mx-4" >
    <img id="appsImage" src="../../assets/images/Login_challenge_landing.svg" >
</div>
</div>

